export const API_URL = '/api'
export const BASE_URL = '/residents'

export const API_ROUTES = {
  SIGN_IN: `/auth/login`,
  SIGN_OUT: `/auth/logout`,
  GET_USER: `/me/`,
  REFRESH_TOKEN: `/auth/renew`,
  CONTRACTS: `/contracts`,
  ANNOUNCEMENTS: `/announcements`,
  REQUESTS: `/requests`,
  RESDIENTS: `/residents`,
  VEHICLES: `/vehicles`,
  PETS: `/pets`,
  PROFILE: `/me/profile`,
  FORGOT_PASSWORD: `/auth/forgot-password`,
  RESET_PASSWORD: `/auth/reset-password`,
}

export const APP_ROUTES = {
  HOME: "/",
  SIGN_IN: '/login',
}

export const IMG_type = "image"
export const FloorPlan_type = "floor_plan"
export const ALLOWED_IMAGES_TYPE = ['.jpeg', '.png', '.jpg']
export const ALLOWED_FILES_TYPE = [".pdf", ".png", ".jpg", ".jpeg"]
export const MAX_FILE_SIZE_MB = 5
export const MAX_IMAGE_SIZE_MB = 2

export const DAYS_OF_WEEK = [
  { day: 'sunday', code: 'SU' },
  { day: 'monday', code: 'MO' },
  { day: 'tuesday', code: 'TU' },
  { day: 'wednesday', code: 'WE' },
  { day: 'thursday', code: 'TH' },
  { day: 'friday', code: 'FR' },
  { day: 'saturday', code: 'SA' }
];

export const CALENDAR_YEARS_UPPER_LIMIT = 5;