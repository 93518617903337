import React, { useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import AuthContext from './auth-context';

function AppRoutes() {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();
    const ctx = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return ctx.isLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default AppRoutes;
