import React, { useContext, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate, useParams } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import AuthContext from '../../routes/auth-context';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faTicket, faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';


const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent() {
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const { t } = useTranslation();

    const [closeSidebar, setCloseSidebar] = useState(false);

    const { TenantName } = useParams();
    const ctx = useContext(AuthContext);

    async function logout() {
        ctx.onLogout();
        navigate(convertSlugToUrl(TenantName, SLUGS.login, {}));
    }

    function onClick(slug, parameters = {}, qsp = {}) {
        setCloseSidebar(true);
        navigate(convertSlugToUrl(TenantName, slug, parameters, qsp));
    }

    return (
        <Menu isMobile={isMobile} closeSidebar={closeSidebar} setCloseSidebar={setCloseSidebar}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.main}
                title={t('main_page')}
                icon={() => <FontAwesomeIcon icon={faHome} />}
                onClick={() => onClick(SLUGS.main)}
            />
            <MenuItem
                id={SLUGS.residentsRequests}
                items={[SLUGS.residentsNewRequest, SLUGS.residentsPerviousRequests]}
                title={t('residents_requests')}
                icon={() => <FontAwesomeIcon icon={faTools} />}
            >
                <MenuItem
                    id={SLUGS.residentsNewRequest}
                    title={t('new_request')}
                    level={2}
                    icon={() => <FontAwesomeIcon icon={faTools} />}
                    onClick={() => onClick(SLUGS.residentsNewRequest)}
                />
                <MenuItem
                    id={SLUGS.residentsPerviousRequests}
                    title={t('pervious_requests')}
                    level={2}
                    icon={() => <FontAwesomeIcon icon={faTicket} />}
                    onClick={() => onClick(SLUGS.residentsPerviousRequests, {}, { status: 'open' })}
                />
            </MenuItem>
            <div className={classes.separator}></div>
            <MenuItem id='logout' title={t('logout')} icon={() => <FontAwesomeIcon icon={faSignOutAlt} />}
                onClick={logout} />
        </Menu>
    );
}

export default SidebarComponent;
