import React, { useContext, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from 'hooks/useSidebar';
import SLUGS from 'resources/slugs';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownComponent from 'components/dropdown';
import LanguageSelector from 'components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from 'assets/default-avatar-icon.jpg';
import AuthContext from '../../routes/auth-context';
import { convertSlugToUrl } from 'resources/utilities';
import { useParams } from 'react-router-dom';
import { converToAPIUrl } from 'resources/utilities';
import { API_ROUTES } from 'resources/constants';



const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    avatarRight: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginRight: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginRight: 14
        }
    },

    container: {
        height: 40
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 15,
        marginRight: 15,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginRight: 8,
            marginLeft: 8
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: ({ isRTL }) => `${isRTL ? 0 : 30}px`,
            marginRight: ({ isRTL }) => `${isRTL ? 30 : 0}px`
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: (isRTL) => `${isRTL ? 0 : 10}px`,
        marginRight: (isRTL) => `${isRTL ? 10 : 0}px`,
        '@media (max-width: 768px)': {
            marginLeft: (isRTL) => `${isRTL ? 0 : 6}px`,
            marginRight: (isRTL) => `${isRTL ? 6 : 0}px`
        }
    }
}));

function HeaderComponent() {

    const { TenantName } = useParams();
    const ctx = useContext(AuthContext);
    const user = ctx.getUser();

    async function logout() {
        ctx.onLogout();
        navigate(convertSlugToUrl(TenantName, SLUGS.login, {}));
    }
    const navigate = useNavigate();
    const [imageError, setImageError] = useState(false);
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [isRTL, setIsRTL] = useState(i18n.dir(i18n.language) === 'rtl');
    const classes = useStyles({ theme, isRTL });

    useEffect(() => {
        setIsRTL(i18n.dir(i18n.language) === 'rtl');
    }, [i18n.language]);


    let title;
    switch (true) {
        case currentItem === SLUGS.main:
            title = t('main_page');
            break;
        case currentItem === SLUGS.residentsNewRequest:
            title = t('new_request');
            break;
        case currentItem === SLUGS.residentsPerviousRequests:
            title = t('pervious_requests');
            break;
        default:
            title = '';
    }


    return (
        <>
            <Row className={classes.container} vertical='center' horizontal='space-between'>
                <span className={classes.title}>{title}</span>
                <Row vertical='center'>
                    <div className={classes.iconStyles}>
                    </div>
                    <div className={classes.iconStyles}>
                        <LanguageSelector type='exclusive' />
                    </div>
                    <div className={classes.separator} />
                    {user && (
                        <DropdownComponent
                            label={
                                <>
                                    <span className={classes.name}>{user.name} {user.last_name}</span>
                                    <img
                                        src={imageError ? DefaultAvatar : converToAPIUrl(TenantName, API_ROUTES.PROFILE + '/avatar', {})}
                                        alt='avatar'
                                        className={isRTL ? classes.avatarRight : classes.avatar}
                                        onError={() => setImageError(true)}
                                    />
                                </>
                            }
                            options={[
                                {
                                    label: t('profile'),
                                    onClick: () => navigate(convertSlugToUrl(TenantName, SLUGS.profile, {}))
                                },
                                {
                                    label: t('logout'),
                                    onClick: () => logout()
                                }
                            ]}
                            position={{
                                top: 52,
                                right: -6
                            }}
                        />
                    )}
                </Row>
            </Row>
        </>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
