import React, { useState, useEffect } from 'react';
import { useTheme } from 'react-jss';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';

const getMenuStyles = ({ theme, isRTL }) => ({
    bmBurgerButton: {
        position: 'absolute',
        width: 26,
        height: 20,
        left: isRTL ? 'auto' : 30,
        right: isRTL ? 30 : 'auto',
        top: 40,
        zIndex: 19
    },
    bmBurgerBars: {
        background: theme.color.veryDarkGrayishBlue
    },
    bmBurgerBarsHover: {
        background: theme.color.darkRed
    },
    bmCrossButton: {
        display: 'none'
    },
    bmCross: {
        background: theme.color.grayishBlue3
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        width: 255,
        zIndex: 30
    },
    bmMenu: {
        background: theme.color.black
    },
    bmItem: {
        outline: 'none',
        '&:focus': {
            outline: 'none',
        }
    },
    bmMorphShape: {
        fill: theme.color.veryDarkGrayishBlue
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        zIndex: 20
    },
});

function MenuComponent({ children, isMobile, closeSidebar, setCloseSidebar }) {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const isRTL = i18n.dir(currentLanguage) === 'rtl';

    const theme = useTheme();
    const menuStyles = getMenuStyles({ theme, isRTL });
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (closeSidebar) {
            setIsOpen(false);
            setCloseSidebar(false);
        }
    }, [closeSidebar]);

    return (
        <Menu right={isRTL}
            isOpen={!isMobile || isOpen}
            noOverlay={!isMobile}
            disableCloseOnEsc
            styles={menuStyles}
            onStateChange={(state) => setIsOpen(state.isOpen)}
        >
            {children}
        </Menu>
    );
}

export default MenuComponent;
