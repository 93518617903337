import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SidebarProvider } from 'hooks/useSidebar';

function SidebarContext({ children }) {
    const { pathname } = useLocation();
    const { TenantName } = useParams();

    const defaultItem = pathname.replace(`/${TenantName}`, '');
    return <SidebarProvider defaultItem={defaultItem}>{children}</SidebarProvider>;
}
export default SidebarContext;
