import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';


function LanguageSelector(type = 'all') {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div className='lang-sel override_direction'>
            {(currentLanguage !== 'en' || type === 'all') && (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        i18n.changeLanguage('en');
                    }}
                >
                    <ReactCountryFlag
                        countryCode="GB"
                        svg
                    />
                </a>
            )}
            {(currentLanguage !== 'ar' || type === 'all') && (
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        i18n.changeLanguage('ar');
                    }}
                >
                    <ReactCountryFlag
                        countryCode="SA"
                        svg
                    />
                </a>
            )}
        </div>
    );
}

export default LanguageSelector;
