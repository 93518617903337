import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from 'resources/theme';
import AppRoutes from './routes';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { AuthContextProvider } from 'routes/auth-context';
import i18n from 'i18next'; // Updated import

import './i18n';

// Set lang based on i18n
document.documentElement.setAttribute('lang', i18n.language);

ReactDOM.render(
    <ThemeProvider theme={Theme}>
        <Router basename="/residents">
            <Routes>
                <Route path="/:TenantName/*" element={
                    <AuthContextProvider>
                        <AppRoutes />
                    </AuthContextProvider>
                } exact />
            </Routes>
        </Router>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
