import { API_URL } from "./constants";

export function convertSlugToUrl(tenant, slug, parameters = {}, qsp = {}) {
    let url = `/${tenant}/${slug}`;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    if (Object.keys(qsp).length > 0) {
        url += `?${new URLSearchParams(qsp).toString()}`;
    }
    // remove any trailing slashes or double slashes
    url = url.replace(/\/{2,}/g, "/");
    return url;
}

export function converToAPIUrl(tenant, slug, parameters, qsp = {}) {
    let url = `${API_URL}/${tenant}${slug}`;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    if (Object.keys(qsp).length > 0) {
        url += `?${new URLSearchParams(qsp).toString()}`;
    }
    return url;
}

export const convertFileToBase64 = (image) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(image);
    });
};