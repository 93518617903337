import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import slugs from 'resources/slugs';
import { API_ROUTES, API_URL } from '../resources/constants';
import { convertSlugToUrl } from '../resources/utilities';

const AuthContext = React.createContext({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    getUser: () => { },
    onLogout: () => { },
    onLogin: async () => { },
});

export const AuthContextProvider = (props) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { TenantName } = useParams();
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem('isLoggedIn') === 'true'
    );
    const [user, setUser] = useState(null);

    useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = async () => {
        try {
            const response = await fetch(`${API_URL}/${TenantName}${API_ROUTES.GET_USER}`, {
                method: 'GET',
                headers: {
                    'Accept-Language': i18n.language,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setIsLoggedIn(true);
                setUser(data);
            } else {
                setIsLoggedIn(false);
                setUser(null);
            }
        } catch (error) {
            console.error('Error checking login:', error);
        }
    };

    const refreshToken = async () => {
        try {
            const response = await axios({
                method: 'GET',
                headers: {
                    'Accept-Language': i18n.language,
                },
                url: `${API_URL}/${TenantName}${API_ROUTES.REFRESH_TOKEN}`,
            });

            if (response.status === 200) {
                setUser(response.data);
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
    };

    const getUserHandler = () => user;

    const logoutHandler = async () => {
        try {
            const response = await fetch(`${API_URL}/${TenantName}${API_ROUTES.SIGN_OUT}`, {
                method: 'POST',
                headers: {
                    'Accept-Language': i18n.language,
                },
            });

            if (!response.ok) {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        } finally {
            localStorage.setItem('isLoggedIn', false);
            setIsLoggedIn(false);
            setUser(null);
            navigate(convertSlugToUrl(TenantName, slugs.login, {}));
        }
    };

    const loginHandler = async (email, password, keepLoggedIn, messageRef, next) => {
        try {
            const response = await fetch(`${API_URL}/${TenantName}${API_ROUTES.SIGN_IN}`, {
                method: 'POST',
                headers: {
                    'Accept-Language': i18n.language,
                },
                body: JSON.stringify({
                    email,
                    password,
                    keepLoggedIn,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setIsLoggedIn(true);
                setUser(data);
                localStorage.setItem('isLoggedIn', true);
                if (next) {
                    navigate(next);
                }
            } else {
                localStorage.setItem('isLoggedIn', false);
                messageRef.current.show('danger', `${t('login_failed')}: ${data.msg}`);
            }
        } catch (error) {
            console.error('Error logging in:', error);
            messageRef.current.show('danger', t('login_failed'));
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                getUser: getUserHandler,
                onLogout: logoutHandler,
                onLogin: loginHandler,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
