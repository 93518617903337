import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-localstorage-backend';

import enTranslation from './locales/en.json';
import arTranslation from './locales/ar.json';

const resources = {
  en: { translation: enTranslation },
  ar: { translation: arTranslation },
};


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },

  });

i18n.on('languageChanged', (lng) => { document.documentElement.setAttribute('lang', lng); })


export default i18n;