import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

import { convertSlugToUrl } from 'resources/utilities';

const LogingComponent = lazy(() => import('./login'));
const ResetPassword = lazy(() => import('./login/resetPassword'));

function PublicRoutes() {
    const { TenantName } = useParams();
    const location = useLocation();
    const next = location.pathname == convertSlugToUrl(TenantName, SLUGS.login) ? null : location.pathname;

    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Routes>
                <Route path={SLUGS.resetPassword} element={<ResetPassword />} />
                <Route path={SLUGS.login} element={<LogingComponent />} />
                <Route path="*" element={<Navigate to={convertSlugToUrl(TenantName, SLUGS.login, {}, { next: next })} />} />
            </Routes>
        </Suspense >
    );
}

export default PublicRoutes;
